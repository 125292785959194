import {NAV_DROP_DOWN, MOB_MENU, LOGO_URL, SERVICE_TAB, NAV_COLOR, DESKTOP_HIDDEN, ESTIMATION, ESTIMATION_WIDGET, MOBILE_NAV, EST_FORM, EST_BTN, CONTACT_FORM, CONTACT_BTN} from './../types';

const initialState = {
    drop_down : false,
    mob_menu : 'collapse navbar-collapse  df-bold font-nunito mobile-nav',
    logo : '/assets/logos/bluehive_white1.svg',
    mobile_menu : '/assets/icons/mobile-white-menu.svg',
    mobile_menu_class : '',
    serv_tab : {
        java : 'active',
        php : '',
        javascript : ''
    },
    nav_color : 'color-white',
    desktop_hidden : '',
    estimation : false,
    est_widget : [
        {
            img : '/assets/icons/home-web-dev.svg',
            description : 'Web Development',
            active : true
        },
        {
            img : '/assets/icons/home-mob-app.svg',
            description : 'Mobile App Development',
            active : false
        },
        {
            img : '/assets/icons/home-custom-syst.svg',
            description : 'Custom System Development',
            id : 2,
            active : false
        },
        {
            img : '/assets/icons/system-int.svg',
            description : 'System Integration',
            active : false
        },
        {
            img : '/assets/icons/home-cloud-icon.svg',
            description : 'Cloud Services & Solutions',
            active : false
        },
        {
            img : '/assets/icons/home-database.svg',
            description : 'Database Management',
            active : false
        },
        {
            img : '/assets/icons/services-ict.svg',
            description : 'ICT Services',
            active : false
        },
        {
            img : '/assets/icons/services-network.svg',
            description : 'Network & Security',
            active : false
        },
        {
            img : '/assets/icons/services-bus-email.svg',
            description : 'Business Email Solutions',
            active : false
        },
        {
            img : '/assets/icons/services-ecomm.svg',
            description : 'E-commerce Services',
            active : false
        },
        {
            img : '/assets/icons/home-ui.svg',
            description : 'UI/UX Design',
            active : false
        },
        {
            img : '/assets/icons/services-consult.svg',
            description : 'Consultancy Services',
            active : false
        }
    ],
    est_form : {
        first_name : '',
        last_name : '',
        company_name : '',
        company_industry : '',
        email : '',
        mobile_no : '',
        message : '',
        budget : {
            min : 10000,
            max : 1000000
        },
        captcha : '',
        projects : []
    },
    est_btn : 'SEND',
    contact_form : {
        first_name : '',
        last_name : '',
        company_name : '',
        email : '',
        message : '',
        captcha : ''
    },
    contact_btn : 'SEND MESSAGE'
}

const navReducer = (state = initialState, action) => {
    switch (action.type) {
        case NAV_DROP_DOWN:
            return {
                ...state,
                drop_down: action.payload
            }
        case MOB_MENU:
            return {
                ...state,
                mob_menu: action.payload
            }
        case LOGO_URL :
            return {
                ...state,
                logo : action.payload
            }
        case SERVICE_TAB:
            return {
                ...state,
                serv_tab: action.payload
            }
        case NAV_COLOR:
            return {
                ...state,
                nav_color : action.payload 
            }
        case DESKTOP_HIDDEN:
            return {
                ...state,
                desktop_hidden : action.payload
            }
        case ESTIMATION:
            return {
                ...state,
                estimation : action.payload
            }
        case ESTIMATION_WIDGET :
            return {
                ...state,
                est_widget : action.payload
            }
        case MOBILE_NAV : 
            return {
                ...state,
                mobile_menu : action.payload.img,
                mobile_menu_class : action.payload.cls
            }
        case EST_FORM :
            return {
                ...state,
                est_form : action.payload
            }
        case EST_BTN : 
            return {
                ...state,
                est_btn : action.payload
            }
        case CONTACT_FORM : 
            return {
                ...state,
                contact_form : action.payload
            }
        case CONTACT_BTN :
            return {
                ...state,
                contact_btn : action.payload
            }
        default:
            return state;
            break;
    }
}

export default navReducer;
