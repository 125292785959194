import React, { Component, Fragment, useEffect, useState }  from 'react';
import {Link, Router, Redirect, useHistory} from 'react-router-dom';
import NavBar from './../layouts/Navbar';
import { connect } from 'react-redux';
import {imgLogo, serviceTab} from '../../redux/actions/nav.actions';
function Consultancy({nav : {logo, serv_tab}, imgLogo, serviceTab}) {
    // let history = useHistory();
    useState(() => {
        imgLogo(true);
    })
    return (
        <React.Fragment> 
        <section className="services-section main-content services-details-bg content-bg">
            <div className="services-details-header bg-header">
                <div className="services-details-container ">
                    <div className="services-details-content ">
                        <a href="/services" className="services-header-arrow"><img src="/assets/icons/arrow-left.svg" class="hide-on-mobile" /><img src="/assets/media/left-arrow.png" class="show-on-mobile" /> OUR SERVICES</a><br/>
                        <span className="services-header-details color-dark-blue">Consultancy<br/>Services</span>
                        <p className="services-details-context color-dark-blue">We provide our clients with consultancy services to help you achieve the most effective solutions for your business. Whether it is to inquire about a service we provide or to ask for advice on how to best tackle an IT problem your company is facing, we have the experience, patience and passion to meet each request.</p>
                    </div>
                </div>
            </div>
            <div className="solution-offer-container services-details-widget">
                <div className="content-details">
                    <span className="header">Why Choose Bluehive?</span>
                    <p className="content">Our consultancy services ensure you are getting the best solutions for your business. Our clients are at the heart of our venture, so it is important that your inquiries are heard and that you are confident in the value of the services you are receiving.</p>
                </div>
                <div className="widget-list">
                    <div className="widget-item mh-14m">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                            <p>We do not compromise on quality and value. Our company has been operating for 8 years now and the team, expertise and partnerships we have cultivated has given us and edge in the field.</p>
                        </div>
                    </div>
                    <div className="widget-item mh-14m">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                            <p>Our senior consultants have a wide range of expertise and have been in practice for almost 18 years in both the Philippines and the US.</p>   
                        </div>
                    </div>
                    <div className="widget-item ">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                            <p>We do not leave customers hanging and we value client partnerships and long term relationships.</p>   
                        </div>
                    </div>
                    
                </div>
            </div>
          
            <div className="home-estimation">
                <div className="home-estimation-bg"></div>
                <span className="estimation-header">Get An Estimation</span>
                <p className="estimation-content">Want to know roughly how much your project will cost? Waste no time and follow the link below to get an estimate price.</p>
                <a href="/get-an-estimation" className="btn btn-blue1">GET AN ESTIMATION</a>
                <a href="/contact" className="btn btn-blue1">CONTACT US DIRECTLY</a>
            </div>
           
           </section>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    nav: state.nav
});


export default connect(mapStateToProps, {imgLogo, serviceTab}) (Consultancy);