import {SERVICE_TAB} from './../types';

const initialState = {
    serv_tab : {
        java : 'active',
        php : '',
        javascript : ''
    }
}

const serviceReducer = (state = initialState, action) => {
    switch (action.type) {
        case SERVICE_TAB:
            return {
                ...state,
                serv_tab: action.payload
            }
       
        default:
            return state;
            break;
    }
}

export default serviceReducer;
