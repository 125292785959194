import React, { Component, Fragment, useEffect, useState } from "react";
import { Link, Router, Redirect, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  navColor,
  MobMenu,
  captchaContact,
  contactForm,
  expiredContact,
  submitContact,
} from "../redux/actions/nav.actions";
import "pure-react-carousel/dist/react-carousel.es.css";
import ReCAPTCHA from "react-google-recaptcha";

function Contact({
  nav: { nav_color, contact_form, contact_btn },
  navColor,
  MobMenu,
  captchaContact,
  contactForm,
  expiredContact,
  submitContact,
}) {
  // let history = useHistory();
  useState(() => {
    navColor();
    MobMenu(true);
  });

  return (
    <React.Fragment>
      <section className="main-container blog-bg content-bg contact-bg">
        <div className="contact-header bg-header">
          <div className="contact-person"></div>
          <form
            className="contact-container"
            onSubmit={(e) => submitContact(e)}
          >
            <div className="row">
              <div className="col-md-6 row ml-3p mobile-40 list-contact">
                <div className="col-md-12 contact-md-12">
                  <h1 className="header-white contact">
                    Got Any
                    <br /> Questions?
                    <br /> Get In Touch
                  </h1>
                  <ul className="office-list mobile-only mt-5p">
                    <li>OFFICE</li>
                    <li>
                      Web-Jet Building, 88 E. Rodriguez Jr. Ave. Quezon City
                    </li>
                    <li>&nbsp;</li>
                    <li>EMAIL</li>
                    <li>
                      <a href="mailto:info@bluehive.com.ph">
                        info@bluehive.com.ph
                      </a>
                    </li>
                    <li>&nbsp;</li>
                    <li>TELEPHONE</li>
                    {/* <li>
                                            <a href="tel:029788699">(02) 978.8699</a>
                                    </li> */}
                    {/* <li>
                      <a href='tel:026559175 '>(02) 8655.9175 </a>
                    </li> */}
                    <li>
                      <a href="tel:0286529961">(02) 86529961</a>
                    </li>
                    <li>&nbsp;</li>
                    {/* <li>
                                            MOBILE 
                                    </li>
                                    <li>
                                            <a href="tel:09178388699">(0917) 838.8699</a>
                                    </li> */}
                  </ul>
                  <div class="row tablet-hide mobile-hide col-md-12 contact-office-list-div">
                    <div class="col-md-7">
                      <ul className="contact-office-list office-list  mt-5p">
                        <li class="f16-18">OFFICE</li>
                        <li class="f14-18">
                          Web-Jet Building, 88 E. Rodriguez Jr. Ave. Quezon City
                        </li>
                        <li>&nbsp;</li>
                        <li class="f16-18">EMAIL</li>
                        <li class="f14-18">
                          <a href="mailto:info@bluehive.com.ph">
                            info@bluehive.com.ph
                          </a>
                        </li>
                        <li>&nbsp;</li>
                      </ul>
                    </div>
                    <div class="col-md-5">
                      <ul className="contact-office-list office-list desktop-only mt-5p">
                        <li class="f16-18">TELEPHONE</li>
                        {/* <li class="f14-18">
                                                    <a href="tel:029788699">(02) 978.8699</a>
                                            </li> */}
                        {/* <li>
                          <a href="tel:026559175 ">(02) 8655.9175 </a>
                        </li> */}
                        <li>
                          <a href="tel:0286529961">(02) 86529961</a>
                        </li>
                        <li>&nbsp;</li>
                        {/* <li class="f16-18">
                                                    MOBILE 
                                            </li>
                                            <li class="f14-18">
                                                    <a href="tel:09178388699">(0917) 838.8699</a>
                                            </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 row mt-4 pl-7p mobile-60">
                <div className="col-md-6 mobile-100">
                  <label>First Name</label>
                  <input
                    className="form-control contact-input"
                    value={contact_form.first_name}
                    onChange={(e) => contactForm(e, "first_name")}
                    required
                  />
                </div>
                <div className="col-md-6 mobile-100">
                  <label>Last Name</label>
                  <input
                    className="form-control  contact-input"
                    value={contact_form.last_name}
                    onChange={(e) => contactForm(e, "last_name")}
                    required
                  />
                </div>
                <div className="col-md-6 mobile-100">
                  <label>Your Email</label>
                  <input
                    className="form-control contact-input"
                    value={contact_form.email}
                    onChange={(e) => contactForm(e, "email")}
                    required
                  />
                </div>
                <div className="col-md-6 mobile-100 ">
                  <label>Company Name</label>
                  <input
                    className="form-control contact-input"
                    value={contact_form.company_name}
                    onChange={(e) => contactForm(e, "company_name")}
                    required
                  />
                </div>
                <div className="col-md-12 mobile-100">
                  <label>Your Message</label>
                  <textarea
                    className="form-control no-resize contact-input"
                    rows="10"
                    value={contact_form.message}
                    onChange={(e) => contactForm(e, "message")}
                    required
                  ></textarea>
                </div>

                <div class="col-md-12">
                  <ReCAPTCHA
                    sitekey="6LflAn0kAAAAANeINj-YGhfuNv9bQ61W_dczP0JL"
                    onChange={captchaContact}
                    onExpired={expiredContact}
                    size="normal"
                  />
                </div>
                <div className="col-md-12 mobile-100">
                  <button
                    className="btn btn-send mt-2m"
                    type="submit"
                    onSubmit={(e) => submitContact(e)}
                  >
                    {contact_btn}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  nav: state.nav,
});

export default connect(mapStateToProps, {
  navColor,
  MobMenu,
  captchaContact,
  contactForm,
  expiredContact,
  submitContact,
})(Contact);
