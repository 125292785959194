import React, { Component, Fragment, useEffect, useState }  from 'react';
import {Link, Router, Redirect, useHistory} from 'react-router-dom';
import NavBar from './../layouts/Navbar';
import { connect } from 'react-redux';
import {imgLogo, serviceTab} from '../../redux/actions/nav.actions';
function SysInt({nav : {logo, serv_tab}, imgLogo, serviceTab}) {
    // let history = useHistory();
    useState(() => {
        imgLogo(true);
    })
    return (
        <React.Fragment> 
        <section className="services-section main-content services-details-bg content-bg">
            <div className="services-details-header bg-header">
                <div class="bg-gradient"></div>
                <div className="services-details-container ">
                    <div className="services-details-content ">
                        <a href="/services" className="services-header-arrow"><img src="/assets/icons/arrow-left.svg" class="hide-on-mobile" /><img src="/assets/media/left-arrow.png" class="show-on-mobile" /> OUR SERVICES</a><br/>
                        <span className="services-header-details color-dark-blue">System<br/>Integration</span>
                        <p className="services-details-context color-dark-blue">At Bluehive, we specialize in providing efficient, reliable and scalable business solutions. Our expert development team offer a wide range of tech services to help your company achieve it's goals. Discover what we have to offer below.</p>
                    </div>
                </div>
            </div>
            <div className="solution-offer-container services-details-widget">
                <div className="content-details">
                    <span className="header">Why Choose Bluehive?</span>
                    <p className="content">We have worked with several clients already, helping consolidate their hardware and software systems to create an integrated system that boosts productivity, reach, profit and growth. With Blue Hive, you can be assured that our expertise, problem solving skills and attention to detail will produce effective results.</p>
                </div>
                <div className="widget-list">
                    <div className="widget-item">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                            <p>We take our client's needs, constraints and budget into consideration to try and provide the most cost effective solutions.</p>
                        </div>
                    </div>
                    <div className="widget-item">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                            <p>We keep your business goals and end user needs at the heart of every project, ensuring usability, scalability and effectiveness.</p>
                        </div>
                    </div>
                    <div className="widget-item">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                            <p>We are in partnerships with industry trailblazers and have access to innovative resources that are leading the way in software development.</p>
                        </div>
                    </div>
                    <div className="widget-item">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                            <p>Our team expertise and performance ensures modern, stable, secure and maintainable systems that run smoothly and are open to growth and further development.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="solution-offer-container cwww-container">
                <div className="content-details">
                    <span className="header">Clients We've Worked With</span>
                </div>
                <div className="cwww-widget">
                    <img src="/assets/logos/projects/tipsy-pig.svg" />
                    <img src="/assets/logos/projects/daiesumi-logo.svg" />
                    <img src="/assets/logos/projects/qpax-logo.svg" style={{display: 'none'}} />
                </div>
            </div>
            <div className="home-estimation">
                <div className="home-estimation-bg"></div>
                <span className="estimation-header">Get An Estimation</span>
                <p className="estimation-content">Want to know roughly how much your project will cost? Waste no time and follow the link below to get an estimate price.</p>
                <a href="/get-an-estimation" className="btn btn-blue1">GET AN ESTIMATION</a>
                <a href="/contact" className="btn btn-blue1">CONTACT US DIRECTLY</a>
            </div>
           
           </section>
        </React.Fragment>

        
    )
}

const mapStateToProps = state => ({
    nav: state.nav
});


export default connect(mapStateToProps, {imgLogo, serviceTab}) (SysInt);