export const NAV_DROP_DOWN = 'NAV_DROP_DOWN';
export const MOB_MENU = 'MOB_MENU';
export const LOGO_URL = 'LOGO_URL';
export const SERVICE_TAB = 'SERVICE_TAB';
export const NAV_COLOR = 'NAV_COLOR';
export const DESKTOP_HIDDEN = 'DESKTOP_HIDDEN';
export const ESTIMATION = 'ESTIMATION';
export const ESTIMATION_WIDGET = 'ESTIMATION_WIDGET';
export const MOBILE_NAV = 'MOBILE_NAV';
export const EST_FORM = 'EST_FORM';
export const EST_BTN = 'EST_BTN';
export const CONTACT_FORM = 'CONTACT_FORM';
export const CONTACT_BTN = 'CONTACT_BTN';