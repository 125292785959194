import React, { Component, Fragment, useEffect, useState }  from 'react';
import {Link, Router, Redirect, useHistory} from 'react-router-dom';
import NavBar from './layouts/Navbar';
import { connect } from 'react-redux';
import {imgLogo, serviceTab} from '../redux/actions/nav.actions';
function Service({nav : {logo, serv_tab}, imgLogo, serviceTab}) {
    // let history = useHistory();
    useState(() => {
        imgLogo(true);
    })
    return (
        <React.Fragment> 
        <section className="service-content  services-bg content-bg">
            <div className="services-header bg-header">
                <div className="services-container ">
                    <div className="services-content-main color-dark-blue">
                        <span className="services-header-content">OUR SERVICES</span><br/>
                        <span className="services-expert">Efficient <br/>& Reliable <br/>Solutions</span>
                        <p className="services-details">At Bluehive, we specialize in providing efficient, reliable and scalable business solutions. Our expert development team offer a wide range of tech services to help your company achieve it's goals. Discover what we have to offer below.</p>
                    </div>
                </div>
            </div>
            <div className="solution-offer-container mobile-container main-content">
                <div className="content-details">
                    <span className="header sub-header">Solutions We Offer</span>
                    <p className="content content-details">At Blue Hive Inc. our skillsets cover a wide range of technologies and services that produce meaningful, high quality innovations for our clients.</p>
                </div>
                <div className="blank-hexa-bg"></div>
                <div className="solution-widget">
                    <div className="widget-item text-center widget-no-mh">
                        <img src="/assets/icons/home-system-int.svg" className="widget-img" />
                        <span className="widget-header widget-header-mobile show-on-mobile">System Integration</span>
                        <span className="widget-header widget-header-mobile hide-on-mobile">System<br/>Integration</span>
                        <p className="widget-content widget-text">We fix, improve, and consolidate hardware and software systems to ensure seamless running and consistency</p>
                        <a href="/services/system-integration" className="btn btn-blue-outline widget-btn">READ MORE</a>
                    </div>
                    <div className="widget-item text-center widget-no-mh">
                        <img src="/assets/icons/home-mob-app.svg" className="widget-img" />
                        <span className="widget-header widget-header-mobile">Mobile App Development</span>
                        <p className="widget-content widget-text">We develop mobile applications to help you gain marketing advantage</p>
                        <a href="/services/mobile-app-development" className="btn btn-blue-outline widget-btn">READ MORE</a>
                    </div>
                    <div className="widget-item text-center widget-no-mh">
                        <img src="/assets/icons/home-custom-syst.svg" className="widget-img" />
                        <span className="widget-header widget-header-mobile">Custom System Development</span>
                        <p className="widget-content widget-text">Our team utilizes digital solutions that tailors to the client's specific business needs</p>
                        <a href="/services/custom-system-development" className="btn btn-blue-outline widget-btn">READ MORE</a>
                    </div>
                    <div className="widget-item text-center widget-no-mh">
                        <img src="/assets/icons/home-web-dev.svg" className="widget-img" />
                        <span className="widget-header widget-header-mobile show-on-mobile">Web Development</span>
                        <span className="widget-header widget-header-mobile hide-on-mobile">Web App &<br/> Website<br/> Development</span>
                        <p className="widget-content widget-text">We’ll aid you in meeting your branding and marketing necessities</p>
                        <a href="/services/web-app-and-website-development" className="btn btn-blue-outline widget-btn">READ MORE</a>
                    </div>
                    <div className="widget-item text-center widget-no-mh">
                        <img src="/assets/icons/home-cloud-icon.svg" className="widget-img" />
                        <span className="widget-header widget-header-mobile show-on-mobile">Cloud Solutions & Services</span>
                        <span className="widget-header widget-header-mobile hide-on-mobile">Cloud Solutions<br/>& Services</span>
                        <p className="widget-content widget-text">We offer hosting services that utilize our reliable cloud servers</p>
                        <a href="/services/cloud-solutions-and-services" className="btn btn-blue-outline widget-btn">READ MORE</a>
                    </div>
                    <div className="widget-item text-center widget-no-mh">
                        <img src="/assets/icons/home-database.svg" className="widget-img" />
                        <span className="widget-header widget-header-mobile">Database Management</span>

                        <p className="widget-content widget-text show-on-mobile">We give consultations and implement database technologies to ensure fast and reliable systems</p>
                        <p className="widget-content widget-text hide-on-mobile">We offer hosting services that utilize our reliable cloud servers</p>
                        <a href="/services/database-management" className="btn btn-blue-outline widget-btn">READ MORE</a>
                    </div>
                    <div className="widget-item text-center widget-no-mh">
                        <img src="/assets/icons/services-ict.svg" className="widget-img" />
                        <span className="widget-header widget-header-mobile">ICT Services</span>
                        <p className="widget-content widget-text">We offer a range of ICT services that encompass a variety of technologies, frameworks and applications</p>
                        <a href="/services/ict-services" className="btn btn-blue-outline widget-btn">READ MORE</a>
                    </div>
                    <div className="widget-item text-center widget-no-mh">
                        <img src="/assets/icons/services-network.svg" className="widget-img" />
                        <span className="widget-header widget-header-mobile">Network & Security</span>
                        <p className="widget-content widget-text">We provide a variety of preventive and reactive measures to ensure a safe and reliable service</p>
                        <a href="/services/network-and-security" className="btn btn-blue-outline widget-btn">READ MORE</a>
                    </div>
                    <div className="widget-item text-center widget-no-mh">
                        <img src="/assets/icons/services-bus-email.svg" className="widget-img" />
                        <span className="widget-header widget-header-mobile">Business Email Solutions</span>
                        <p className="widget-content widget-text">We offer business email hosting services that allows for more control and customizability</p>
                        <a href="/services/business-email-solutions" className="btn btn-blue-outline widget-btn">READ MORE</a>
                    </div>
                    <div className="widget-item text-center widget-no-mh">
                        <img src="/assets/icons/services-ecomm.svg" className="widget-img" />
                        <span className="widget-header widget-header-mobile">Ecommerce Services</span>
                        <p className="widget-content widget-text">We develop for a variety of ecommerce platforms that can be tailored to suit your specific business needs</p>
                        <a href="/services/ecommerce-services" className="btn btn-blue-outline widget-btn">READ MORE</a>
                    </div>
                    <div className="widget-item text-center widget-no-mh">
                        <img src="/assets/icons/services-consult.svg" className="widget-img" />
                        <span className="widget-header widget-header-mobile">Consultancy Services</span>
                        <p className="widget-content widget-text">We provide consultation services to diagnose issues and propose effective solutions</p>
                        <a href="/services/consultancy-services" className="btn btn-blue-outline widget-btn">READ MORE</a>
                    </div>
                </div>
            </div>
            <div className="twww-container mobile-container main-content">
                <div className="twww-bg"></div>
                <div className="content-details">
                    <span className="header sub-header show-on-mobile">Technologies <br/>We Use</span>
                    <span className="header sub-header hide-on-mobile">Technologies We Work With</span>
                    
                    <p className="content content-details show-on-mobile">At Blue Hive Inc. our skillsets cover a wide range of technologies and services that produce meaningful, high quality innovations for our clients.</p>
                    <p className="content content-details hide-on-mobile">Our team are always striving to advance our skills by adapting to the ever changing digital landscape. We provide modern solutions for the modern market.</p>
                </div>
                <div className="solution-tabs">
                    <div className="tab-header">
                        <div className={serv_tab.java + " tab-item"}>
                            <a className="tab-btn" onClick={(e) => serviceTab (e, 'java')}>
                                <img src="/assets/icons/services-java.svg" /><br/>
                                <span className="label sub-header">JAVA</span>
                            </a>
                        </div>
                        <div className={serv_tab.php + " tab-item"}>
                            <a className="tab-btn" onClick={(e) => serviceTab (e, 'php')}>
                                <img src="/assets/icons/services-php.svg" /><br/>
                                <span className="label sub-header">WEB</span>
                            </a>
                        </div>
                        <div className={serv_tab.javascript + " tab-item"}>
                            <a  className="tab-btn" onClick={(e) => serviceTab (e, 'javascript')}>
                                <img src="/assets/icons/services-javascript.svg" /><br/>
                                <span className="label sub-header">JavaScript</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="tab-content">
                    <div className={serv_tab.java + " tab-pane"}>
                        <div className="java-content tab-pane-context">
                            <span className="java-header header sub-header">JAVA & J2EE</span>
                            <div className="java-context context mobile-content-details">
                                <p>
                                Our experienced team of Java developers specialize in outsourced Java development and services to help any industry by providing cost-effective solutions to your projects. 
                                </p>
                                <p>
                                    We offer the following Java services: 
                                </p>
                                <ul>
                                    <li>Business GUI applications </li>
                                    <li>Software development of</li>
                                    <li>Business process automation (Logistics, CRM, CMS, B2B, B2C)</li>
                                    <li>Application migration and integration</li>
                                    <li>IT security</li>
                                    <li>Administration and maintenance of Java applications.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="java-img tab-pane-img">
                            <center>
                            <img className="img-90" src="/assets/media/java.png"/>
                            <img className="img-90" src="/assets/media/j2ee.png"/>
                            </center>
                        </div>
                    </div>
                    <div className={serv_tab.php + " tab-pane"}>
                        <div className="tab-pane-context">
                            <span className="header sub-header">WEB</span>
                            <div className="context mobile-content-details">
                                <p>Most developers prefer PHP for its low maintenance cost, strong and fast performance and reliability. Its ease of use and compatibility with most operating systems as well as a wide range of server solutions and databases makes it an ideal choice for performance and functionality. At Bluehive, we are strong partisan of PHP and its latest version PHP7 has proven formidable.</p>
                                <p>We offer the following PHP services:</p>
                                <ul>
                                    <li>Code Igniter Framework</li>
                                    <li>Laravel Framework</li>
                                    <li>API Integration</li>
                                    <li>PHP Connection Handling & Security</li>
                                    <li>PHP Speed Optimization & Maintenance</li>
                                </ul>
                            </div>
                        </div>
                        <div className="java-img tab-pane-img">
                            <center>
                            <img className="img-50" src="/assets/icons/css3.svg" />
                            <img className="img-50" src="/assets/icons/code-igniter.svg" />
                            <img className="img-50 php-7" src="/assets/icons/php7.png" />
                            <img className="img-50 " src="/assets/icons/html5.svg" />
                            <img className="img-90" src="/assets/icons/laravel.svg" />
                            </center>
                        </div>
                    </div>
                    <div className={serv_tab.javascript + " tab-pane"}>
                        <div className="tab-pane-context">
                            <span className="header sub-header">JavaScript</span>
                            <div className="context mobile-content-details">
                                <p>Primarily used for the web, JavaScript is a scripting language used to make websites dynamic and interactive. Commonly working alongside HTML code, it allows pages to react to events, create special effects, use variable text, validate data and so much more. It has become an indispensable part of the web development process in this rapidly growing industry. </p>
                                <p>Over the years, JavaScript has been proven robust and effective. It has spawned multiple variations, frameworks and tons of libraries that aid developers in solving problems by providing quick and reliable solutions. Modern frameworks such as Node.js and Angular.js has pushed the scope of this language to provide even more powerful solutions than ever before.</p>
                            </div>
                        </div>
                        <div className="java-img tab-pane-img">
                            <center>
                            <img className="img-50" src="/assets/icons/javascript.svg" />
                            <img className="img-50" src="/assets/icons/angularjs.svg" />
                            <img className="img-50" src="/assets/icons/nodejs.svg" />
                            <img className="img-50 " src="/assets/icons/reactjs.svg" />
                            <img className="img-90" src="/assets/icons/jquery.svg" />
                            </center>
                        </div>
                    </div>
                </div>
            </div>
           <div className="gaq-main">
                <div className="gaq-bg"></div>
            <div className="gaq-container mobile-container main-content">
                    
                <div className="content-details">
                        <span className="header sub-header">Got A Question?</span>
                        <p className="content mobile-content-details">Interested in a project? Want a price estimation? Click below to send us a message about the details of your project or request a free estimation.</p>
                        <a href="/get-an-estimation" className="btn btn-blue1 mobile-gaq-btn show-on-mobile">GET ESTIMATION</a>
                        <a href="/contact" className="btn btn-blue1 mobile-gaq-btn show-on-mobile">CONTACT US</a>
                        <a href="/get-an-estimation" className="btn btn-blue1 mobile-gaq-btn hide-on-mobile">GET AN ESTIMATION</a>
                        <a href="/contact" className="btn btn-blue1 mobile-gaq-btn hide-on-mobile">CONTACT US DIRECTLY</a>
                </div>
                    
            </div>
           </div> 
           </section>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    nav: state.nav
});


export default connect(mapStateToProps, {imgLogo, serviceTab}) (Service);