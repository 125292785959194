import React, { Component, Fragment, useEffect, useState }  from 'react';
import {Link, Router, Redirect, useHistory} from 'react-router-dom';
import NavBar from './../layouts/Navbar';
import { connect } from 'react-redux';
import {imgLogo, serviceTab} from '../../redux/actions/nav.actions';
function NetworkSecurity({nav : {logo, serv_tab}, imgLogo, serviceTab}) {
    // let history = useHistory();
    useState(() => {
        imgLogo(true);
    })
    return (
        <React.Fragment> 
        <section className="services-section main-content services-details-bg content-bg">
            <div className="services-details-header bg-header">
                <div className="services-details-container ">
                    <div className="services-details-content ">
                        <a href="/services" className="services-header-arrow"><img src="/assets/icons/arrow-left.svg" class="hide-on-mobile" /><img src="/assets/media/left-arrow.png" class="show-on-mobile" /> OUR SERVICES</a><br/>
                        <span className="services-header-details color-dark-blue">Network<br/>& Security</span>
                        <p className="services-details-context color-dark-blue">We provide a secure and reliable internal infrastructure to deliver internet to all your devices. Having a well-implemented network and security system in place will greatly improve productivity and provide a secure digital working environment. With Bluehive, you can count on us to provide a hassle-free deployment of a robust and effective system.</p>
                    </div>
                </div>
            </div>
            <div className="solution-offer-container services-details-widget">
                <div className="content-details">
                    <span className="header">Why Choose Bluehive?</span>
                    <p className="content">Blue Hive Inc. provides efficient and reliable network and security solutions that aids your company in executing your business goals and maximizing the efficiency of daily operations. The security of your digital working environment is of the utmost importance and we are equipped with the skills and expertise to implement this.</p>
                </div>
                <div className="widget-list">
                    <div className="widget-item">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                           <p>Whether through WIFI or Direct LAN, our team is equipped to provide a tidy and efficient cabling system to deliver optimal performance to every device while ensuring an easily maintainable infrastructure.</p>
                        </div>
                    </div>
                    <div className="widget-item ">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                            <p>Our team of experienced engineers specialize in the latest technologies to provide the best and most cost-effective security solutions.</p>   
                        </div>
                    </div>

                    <div className="widget-item ">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                            <p>With an experienced support team at the ready, we can provide a fast and effective response to all your networking needs.</p>   
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className="solution-offer-container cwww-container">
                <div className="content-details">
                    <span className="header">Platforms We've Worked With</span>
                </div>
                <div className="cwww-widget">
                    <img src="/assets/logos/projects/ubiquiti.svg" />
                    <img src="/assets/logos/projects/blitzwifi.svg" />
                    <img src="/assets/logos/projects/cisco.svg" />
                    <div className="text-center">
                    <img className="img-h4m svg-center" src="/assets/logos/projects/sophos.svg" />
                    <img className="img-h8m svg-center" src="/assets/logos/projects/aws.svg" />
                    </div>
                </div>
            </div>
            <div className="home-estimation">
                <div className="home-estimation-bg"></div>
                <span className="estimation-header">Get An Estimation</span>
                <p className="estimation-content">Want to know roughly how much your project will cost? Waste no time and follow the link below to get an estimate price.</p>
                <a href="/get-an-estimation" className="btn btn-blue1">GET AN ESTIMATION</a>
                <a href="/contact" className="btn btn-blue1">CONTACT US DIRECTLY</a>
            </div>
           
           </section>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    nav: state.nav
});


export default connect(mapStateToProps, {imgLogo, serviceTab}) (NetworkSecurity);