import React, { Component, Fragment, useEffect, useState } from 'react';
import { Link, Router, Redirect, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  navColor,
  EstimationAction,
  imgLogo,
  MobMenu,
  estForm,
  budgetEst,
  submitEst,
  captchaEst,
  expiredEst,
} from '../../redux/actions/nav.actions';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import ReCAPTCHA from 'react-google-recaptcha';

function valuetext(value) {
  return `₱ ${value}`;
}
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});
const CustomSlider = withStyles({
  root: {
    color: '#00C0FF',
    height: 3,
    padding: '13px 0',
  },
  thumb: {
    height: 27,
    width: 27,
    backgroundColor: '#00C0FF',
    border: '3px solid currentColor',
    marginTop: -12,
    marginLeft: -13,
    boxShadow: '#ebebeb 0 2px 2px',
    '&:focus, &:hover, &$active': {
      boxShadow: '#ccc 0 2px 3px 1px',
    },
    '& .bar': {
      // display: inline-block !important;
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  active: {},
  track: {
    height: 6,
  },
  rail: {
    color: '#d8d8d8',
    opacity: 1,
    height: 6,
  },
})(Slider);

function CustomThumbSlider(props) {
  return (
    <span {...props}>
      <span className='bar' />
      <span className='bar' />
      <span className='bar' />
    </span>
  );
}
function EstDetails({
  nav: { nav_color, est_form, est_btn },
  navColor,
  EstimationAction,
  imgLogo,
  MobMenu,
  estForm,
  budgetEst,
  submitEst,
  captchaEst,
  expiredEst,
}) {
  const [value, setValue] = React.useState([10000, 1000000]);
  const [min, setMin] = React.useState('10k');
  const [max, setMax] = React.useState('1M');

  useState(() => {
    navColor();
    EstimationAction();
    imgLogo(true);
    MobMenu();
  });
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setMin(abbreviateNumber(newValue[0], 0, 0));
    setMax(abbreviateNumber(newValue[1], 0, 0));
    budgetEst(newValue);
  };

  const SI_SYMBOLS = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

  const abbreviateNumber = (number, minDigits, maxDigits) => {
    if (number === 0) return number;

    // determines SI symbol
    const tier = Math.floor(Math.log10(Math.abs(number)) / 3);

    // get suffix and determine scale
    const suffix = SI_SYMBOLS[tier];
    const scale = 10 ** (tier * 3);

    // scale the number
    const scaled = number / scale;

    // format number and add suffix
    return (
      scaled.toLocaleString(undefined, {
        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits,
      }) + suffix
    );
  };

  const industries = [
    'Accounting',
    'Administration & Office Support',
    'Advertising, Arts & Media',
    'Banking & Financial Services',
    'Call Centre & Customer Service',
    'Community Services & Development',
    'Construction',
    'Consulting & Strategy',
    'Design & Architechture',
    'Education & Training',
    'Engineering',
    'Farming, Animals & Conservation',
    'Government & Defence',
    'Healthcare & Medical',
    'Hospitality & Tourism',
    'Human Resources & Recruitment',
    'Information & Communication Technology',
    'Insurance & Superannuation',
    'Legal',
    'Manufacturing, Transport & Logistics',
    'Marketing & Communications',
    'Mining, Resources & Energy',
    'Real Estate & Property',
    'Retail & Consumer Products',
    'Sales',
    'Science & Technology',
    'Sport & Recreation',
    'Trades & Services',
  ];

  return (
    <React.Fragment>
      <section className='main-container estimation-bg content-bg'>
        <div className='estimation-content row'>
          <form className='row' onSubmit={(e) => submitEst(e)}>
            <div className='col-lg-6'>
              <span className='header-semi'>PROJECT ESTIMATION</span>
              <h1 className='mb-1m esti-header'>
                Tell Us About Yourself & Your Company
              </h1>
              <button
                className='btn btn-send btn-send-dekstop'
                type='submit'
                onSubmit={(e) => submitEst(e)}
              >
                {est_btn}
              </button>
            </div>
            <div className='col-lg-6 row theme-form'>
              <div className='col-md-6 mb-1m'>
                <label>First Name</label>
                <input
                  className='form-control'
                  value={est_form.first_name}
                  onChange={(e) => estForm(e, 'first_name')}
                  required
                />
              </div>
              <div className='col-md-6 mb-1m'>
                <label>Last Name</label>
                <input
                  className='form-control'
                  value={est_form.last_name}
                  onChange={(e) => estForm(e, 'last_name')}
                  required
                />
              </div>
              <div className='col-md-6 mb-1m'>
                <label>Your Company Name</label>
                <input
                  className='form-control'
                  value={est_form.company_name}
                  onChange={(e) => estForm(e, 'company_name')}
                  required
                />
              </div>
              <div className='col-md-6 mb-1m'>
                <label>Company Industry</label>
                <select
                  className='form-control'
                  value={est_form.company_industry}
                  onChange={(e) => estForm(e, 'company_industry')}
                  required
                >
                  <option value=''>Select Industry</option>
                  {industries.map((data, key) => {
                    return (
                      <option value={data} key={key}>
                        {data}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className='col-md-6 mb-1m'>
                <label>Email</label>
                <input
                  type='email'
                  className='form-control'
                  value={est_form.email}
                  onChange={(e) => estForm(e, 'email')}
                  required
                />
              </div>
              <div className='col-md-6 mb-1m'>
                <label>Mobile No.</label>
                <input
                  className='form-control'
                  value={est_form.mobile_no}
                  onChange={(e) => estForm(e, 'mobile_no')}
                  required
                />
              </div>
              <div className='col-md-12 mb-1m'>
                <label>Your Budget</label>
                <br />
                <span className='width-100'>
                  ₱ {min} <span className='pull-right'>₱ {max}</span>
                </span>
                <div className={classes.root}>
                  <CustomSlider
                    getAriaLabel={(index) =>
                      index === 0 ? 'Minimum price' : 'Maximum price'
                    }
                    onChange={handleChange}
                    value={value}
                    min={10000}
                    step={5000}
                    max={1000000}
                  />
                </div>
              </div>
              <div className='col-md-12 mb-3m'>
                <label>Message</label>

                <textarea
                  className='form-control no-resize'
                  rows='10'
                  onChange={(e) => estForm(e, 'message')}
                  value={est_form.message}
                ></textarea>
              </div>
              <div class='col-md-12'>
                <ReCAPTCHA
                  sitekey='6LflAn0kAAAAANeINj-YGhfuNv9bQ61W_dczP0JL'
                  onChange={captchaEst}
                  onExpired={expiredEst}
                  size='normal'
                />
              </div>
              <div className='col-md-12'>
                <button
                  className='btn btn-send btn-send-mobile mt-2m'
                  type='submit'
                  onSubmit={(e) => submitEst(e)}
                >
                  {est_btn}
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  nav: state.nav,
});

export default connect(mapStateToProps, {
  navColor,
  EstimationAction,
  imgLogo,
  MobMenu,
  estForm,
  budgetEst,
  submitEst,
  captchaEst,
  expiredEst,
})(EstDetails);
