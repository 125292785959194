import React, { Component, Fragment, useEffect, useState }  from 'react';
import {Link, Router, Redirect, useHistory} from 'react-router-dom';
import NavBar from './../layouts/Navbar';
import { connect } from 'react-redux';
import {imgLogo, serviceTab} from '../../redux/actions/nav.actions';
function WebAppDev({nav : {logo, serv_tab}, imgLogo, serviceTab}) {
    // let history = useHistory();
    useState(() => {
        imgLogo(true);
    })
    return (
        <React.Fragment> 
        <section className="services-section main-content services-details-bg content-bg">
            <div className="services-details-header bg-header">
                <div className="services-details-container ">
                    <div className="services-details-content ">
                        <a href="/services" className="services-header-arrow"><img src="/assets/icons/arrow-left.svg" class="hide-on-mobile" /><img src="/assets/media/left-arrow.png" class="show-on-mobile" /> OUR SERVICES</a><br/>
                        <span className="services-header-details color-dark-blue">Web App <br/>& Website<br/> Development</span>
                        <p className="services-details-context color-dark-blue">With COVID-19 forever affecting the way businesses operate and interact with their consumers, the demand for websites and web applications has risen as a way to pivot during the pandemic. Web development has created spaces for businesses to create a digital presence, reach wider audiences and increase accessibility to their goods and services.</p>
                    </div>
                </div>
            </div>
            <div className="solution-offer-container services-details-widget">
                <div className="content-details">
                    <span className="header">Why Choose Bluehive?</span>
                    <p className="content">At Blue Hive, we are more than experienced with web development. Our vast skillset allows us to navigate the fast paced world of web technologies with confidence as we constantly adapt and develop effective web solutions for the growing digital online market.</p>
                </div>
                <div className="widget-list">
                    <div className="widget-item mh-14m">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                            <p>We provide web development solutions for companies of all sizes and from all sectors. We meet each challenge and request with strategic solutions, custom tailored to fit your specific needs.</p>
                        </div>
                    </div>
                    <div className="widget-item mh-14m">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                            <p>We can develop for a range of functionalities from simple information websites and eshops to more comprehensive web applications.</p>
                        </div>
                    </div>
                    <div className="widget-item ">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                            <p>With a growing number of smaller device users (mobiles, tablets etc.), our web developments are responsive and adaptive in design.</p>
                        </div>
                    </div>
                    <div className="widget-item ">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                            <p>We ensure that strategic UI and UX design principles are implemented during the development stage to ensure user friendliness amongst end users.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="solution-offer-container cwww-container">
                <div className="content-details">
                    <span className="header">Platforms We've Worked With</span>
                </div>
                <div className="cwww-widget">
                    <img src="/assets/logos/projects/wordpress.svg" />
                    <img src="/assets/logos/projects/javascript.svg" />
                    <img src="/assets/logos/projects/jquery.svg" />
                    <img src="/assets/logos/projects/angular.svg" />
                    <img src="/assets/logos/projects/shopify.svg" />
                    <img src="/assets/logos/projects/nodejs.svg" />
                    <img src="/assets/logos/projects/code-igniter.svg" />
                    <img src="/assets/logos/projects/react.svg" />
                    <img src="/assets/logos/projects/java.svg" />
                    <img src="/assets/logos/projects/php.svg" />
                    <img src="/assets/logos/projects/magento.svg" />
                    <img src="/assets/logos/projects/laravel.svg" />
                </div>
            </div>
            <div className="home-estimation">
                <div className="home-estimation-bg"></div>
                <span className="estimation-header">Get An Estimation</span>
                <p className="estimation-content">Want to know roughly how much your project will cost? Waste no time and follow the link below to get an estimate price.</p>
                <a href="/get-an-estimation" className="btn btn-blue1">GET AN ESTIMATION</a>
                <a href="/contact" className="btn btn-blue1">CONTACT US DIRECTLY</a>
            </div>
           
           </section>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    nav: state.nav
});


export default connect(mapStateToProps, {imgLogo, serviceTab}) (WebAppDev);