import React, { Component, Fragment, useEffect, useState }  from 'react';
import {Link, Router, Redirect, useHistory} from 'react-router-dom';
import NavBar from './../layouts/Navbar';
import { connect } from 'react-redux';
import {imgLogo, serviceTab} from '../../redux/actions/nav.actions';
function CloudSolution({nav : {logo, serv_tab}, imgLogo, serviceTab}) {
    // let history = useHistory();
    useState(() => {
        imgLogo(true);
    })
    return (
        <React.Fragment> 
        <section className="services-section main-content services-details-bg content-bg">
            <div className="services-details-header bg-header">
                <div className="services-details-container ">
                    <div className="services-details-content ">
                        <a href="/services" className="services-header-arrow"><img src="/assets/icons/arrow-left.svg" class="hide-on-mobile" /><img src="/assets/media/left-arrow.png" class="show-on-mobile" /> OUR SERVICES</a><br/>
                        <span className="services-header-details color-dark-blue">Cloud Solutions & <br/> Services</span>
                        <p className="services-details-context color-dark-blue">Cloud Solutions are the future of computing. Almost everything that we access today on the internet from our mobile phones and laptops, to a bunch of IoT devices, is all part of the Cloud. With cloud based solutions, your business can benefit from increased scalability, functionality and accessibility. These solutions can encompass a range of services such as full applications, development platforms, servers and even virtual desktops.</p>
                    </div>
                </div>
            </div>
            <div className="solution-offer-container services-details-widget">
                <div className="content-details">
                    <span className="header">Why Choose Bluehive?</span>
                    <p className="content">At Blue Hive, we are experienced in the implementation of various cloud based services, specifically the Amazon Web Services (AWS). Our approach puts your business needs first while offering consult-ation, conceptualization, implementation and management of your cloud services.</p>
                </div>
                <div className="widget-list">
                    <div className="widget-item mh-14m">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                            <p>With cloud solutions, you do not have to worry about purchasing physical hardware such as servers and network switches to power up your applications. AWS Cloud is fast to deploy, cost effective and highly scalable.</p>
                        </div>
                    </div>
                    <div className="widget-item mh-14m">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                            <p>AWS offers on-demand cloud computing platforms that offers individuals and companies a virtual cluster of computers that is available all the time 24/7 through the internet.</p>
                        </div>
                    </div>
                    <div className="widget-item ">
                        <img className="widget-img" src="/assets/icons/check-circle.svg"/>
                        <div className="widget-content">
                            <p>We fully manage your cloud resources to make sure that your product is 99.9999% up and running.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="solution-offer-container cwww-container">
                <div className="content-details">
                    <span className="header">Platforms We've Worked With</span>
                </div>
                <div className="cwww-widget">
                    <img src="/assets/logos/projects/aws.svg" />
                </div>
            </div>
            <div className="home-estimation">
                <div className="home-estimation-bg"></div>
                <span className="estimation-header">Get An Estimation</span>
                <p className="estimation-content">Want to know roughly how much your project will cost? Waste no time and follow the link below to get an estimate price.</p>
                <a href="/get-an-estimation" className="btn btn-blue1">GET AN ESTIMATION</a>
                <a href="/contact" className="btn btn-blue1">CONTACT US DIRECTLY</a>
            </div>
           
           </section>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    nav: state.nav
});


export default connect(mapStateToProps, {imgLogo, serviceTab}) (CloudSolution);