import React, { Component, Fragment, useEffect, useState }  from 'react';
import {Link, Router, Redirect, useHistory} from 'react-router-dom';
import NavBar from './layouts/Navbar';
import { connect } from 'react-redux';
import {navColor, EstimationAction, imgLogo, EstWidget, MobMenu, checkEstItem} from '../redux/actions/nav.actions';

function Estimation({nav : {nav_color, est_widget}, navColor, EstimationAction, imgLogo, EstWidget, MobMenu, checkEstItem}) {
    // let history = useHistory();
    useState(() =>{
        navColor()
        EstimationAction()
        imgLogo(true);
        MobMenu()
        EstWidget(0, est_widget[0]['description'])
    })

    return (
        <React.Fragment> 
        <section className="main-container estimation-bg content-bg">
            <div className="estimation-content">
                    <div className="estimation-header">
                        <span className="header-semi">PROJECT ESTIMATION</span>
                        <h1>What Type of Project Are You Interested In?</h1>
                        {/* <a href="/get-an-estimation/details" className="btn btn-continue">CONTINUE</a> */}
                        <button className="btn btn-continue" onClick={checkEstItem}>CONTINUE</button>
                    </div>
                    <div className=" estimation-widget ">
                        {
                            est_widget.map((data, key) => {
                                return (
                                    <div key={key} className={ (data.active ? 'checked ' : '' ) + "widget-item text-center"} onClick={() => EstWidget (key, data.description)}>
                                        {
                                            data.active ? 
                                            <img src="/assets/icons/check-circle.svg" className="checked-img"/>
                                            : ''
                                        }
                                        
                                        <img class="img-widget" src={data.img} />
                                        <span className="widget-header">{data.description}</span>
                                    </div>
                                )
                            })
                        }
                        <button className="btn btn-continue" onClick={checkEstItem}>CONTINUE</button>
                        {/* <a href="/get-an-estimation/details" className="btn btn-continue">CONTINUE</a> */}
                    </div>
            </div>
        </section>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    nav: state.nav
});

export default connect(mapStateToProps, {navColor, EstimationAction, imgLogo, EstWidget, MobMenu, checkEstItem}) (Estimation);